export default {
    methods: {
        authorizeGoToWebinars() {
            //TODO: add this below back when we are getting the list of webinars from GoTo and we are getting the client secret, etc
            // const redirectUri = `${window.location.origin}/GoToWebinars/callback`
            // const url = `${process.env.VUE_APP_GOTOWEBINAR_BASE_URL}/oauth/v2/authorize?client_id=${process.env.VUE_APP_GOTOWEBINAR_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}`
            // console.log('url to redirect to', url)
            // window.location = url
            //
            this.$router.push({name: 'webinars'})
        }
    }
}
