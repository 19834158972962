<template>
    <div class="container flex flex-col a-webinars">
        <div class="pt-3 pb-8 w-1/6">
            <Search v-model="search" placeholder="Search all webinars" />
        </div>
        <div class="pt-3 pb-8">
            <h1 class="text-black text-xl lg:text-2xl mb-3">Upcoming Webinars</h1>
            <div v-if="filteredUpcomingWebinars.length">
                <div
                    class="flex w-full xl:w-8/12 justify-between cursor-pointer hover:bg-grey-8"
                    :class="{'webinar-row': index !== lastUpcomingWebinar}"
                    v-for="(webinar, index) in filteredUpcomingWebinars"
                    :key="`webinar-${index}`"
                    @click.prevent="goToWebinar(webinar.link)"
                >
                    <div class="flex flex-row">
                        <component :is="'FollowUpIcon'" class="w-14 mr-2 text-dark-blue" />
                        <div class="flex-col mt-4">
                            <span class="text-sm">{{getDateString(webinar.date)}}</span>
                            <h3 class="text-black font-semibold text-lg lg:text-lg pb-3">{{webinar.title}}</h3>
                        </div>
                    </div>
                    <div class="group flex flex-row font-display text-normal text-grey-2 leading-tight items-center pr-3">QPA Clients only <fa-icon icon="chevron-right" class="ml-2" /></div>
                </div>
            </div>
            <div v-else>
                No webinars found matching '{{search}}'
            </div>
        </div>

        <div class="pt-3 pb-8">
            <h1 class="text-black text-xl lg:text-2xl mb-3">Previous Webinars</h1>
            <div class="w-full">
                <div v-if="previousWebinar.total">
                    <div class="grid grid-cols-3 gap-4 lg:gap-10 w-full">
                        <div
                            v-for="(video, index) in previousWebinar.data"
                            :key="`webinar-${index}`"
                            class="col-span-1"
                        >
                            <vue-vimeo-player
                                :ref="`player-${index}`"
                                :video-id="getVimeoVideoId(video.uri)"
                                :video-url="`https://player.vimeo.com/video/${getVimeoVideoId(video.uri)}`"
                                :options="options"
                                class="mb-3 w-full"
                            ></vue-vimeo-player>
                            <h5 class="text-base lg:text-lg">{{video.name}}</h5>
                        </div>
                        <div class="col-span-3 text-center webinar-show-more-row">
                            <a
                                href="#"
                                @click.prevent="showMoreHandler"
                                class="v-btn v-btn--has-bg theme--light v-size--default primary"
                                data-cy="readMoreSummaryButton"
                                v-if="showMoreLink"
                            >
                                <span class="uppercase font-bold">Show more</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-else>
                    No webinars found matching '{{search}}'
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/partials/Search'
import { mapState } from 'vuex'
import Webinars from '@/components/shared/mixins/webinars'
import { VueGotoInstance } from '@/plugins/gotowebinars'
import FollowUpIcon from '@/assets/icons/icons_webinars.svg'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import camelcaseKeys from 'camelcase-keys'
import GET_WEBINARS from '@/graphql/queries/getWebinars.gql'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import RoleMixin from '@/components/shared/mixins/roleHelpers'
import { format, isValid, parseISO } from 'date-fns'

export default {
    name: "WebinarsView",
    mixins: [ Webinars, AuthMixin, RoleMixin ],
    components: { Search, FollowUpIcon, vueVimeoPlayer },
    data() {
        return {
            previousWebinar: {
                data: [],
                total: 0,
                page: 1,
                numberPerPage: 3,
                debounceHandler: null,
                debounce: 500,
                request: null
            },
            options: {
                responsive: true
            },
            search: ''
        }
    },
    apollo: {
        upcomingWebinars: {
            query: GET_WEBINARS,
            debounce: 200,
            variables() {
                return {
                    search: `%${this.search}%`,
                    date: parseISO(format(new Date(), 'yyyy-MM-dd'))
                }
            },
            skip() {
                return !this.canViewResources
            },
            update({ webinars }) {
                // TODO: need to add code to skip this stuff if user doesn't have
                this.loading = false
                return camelcaseKeys(webinars, {deep: true})
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        },
    },
    mounted() {
        if (!this.webinarLoginCode) {
            //TODO: Add back when we have the list of webinars
            // this.authorizeGoToWebinars()
            console.log('code doesnt exist for now')
        }
        //console.log('code', this.webinarLoginCode)
        //console.log('accessObject', this.webinarAccessObject)

        this.loadPreviousWebinars()        
    },
    methods: {
        getDateString(date) {
            return format(parseISO(date), 'E, MMMM d - h:mm (z)')
        },
        loadPreviousWebinars() {
            if (this.previousWebinar.request) {
                this.previousWebinar.request.cancel()
            }
            this.previousWebinar.request = axios.CancelToken.source()
            this.$http.get('/vimeo.webinars/', {
                params: {
                    page: this.previousWebinar.page,
                    results_per_page: this.previousWebinar.numberPerPage,
                    query: this.search
                },
                cancelToken: this.previousWebinar.request.token
            }).then(({ data }) => {
                this.previousWebinar.request = null

                if (data.page == 1) {
                    this.previousWebinar.data = data.data
                    this.previousWebinar.total = data.total
                } else {
                    this.previousWebinar.data = this.previousWebinar.data.concat(data.data)
                }
            }).catch(e => {})
        },
        searchPreviousWebinars() {
            clearTimeout(this.previousWebinar.debounceHandler)

            this.previousWebinar.debounceHandler = setTimeout(() => {
                this.previousWebinar.page = 1
                this.loadPreviousWebinars()
            }, this.previousWebinar.debounce)
        },
        getVimeoVideoId(uri) {
            return uri.replace('/videos/', '')
        },
        goToWebinar(link) {
            if (!link) return
            window.open(link, '_blank')
            // window.location = link
        },
        showMoreHandler() {
            this.previousWebinar.page++
            this.loadPreviousWebinars()
        }
    },
    computed: {
        ...mapState({
            webinarLoginCode: state => state.app.webinarLoginCode,
            webinarAccessObject: state => state.app.webinarAccessObject,
        }),
        filteredUpcomingWebinars() {
            if (!this.upcomingWebinars || !this.upcomingWebinars.length) return []

            return this.upcomingWebinars.filter(webinar => {
                if (this.isAdmin) return true
                if (this.isSurveyor && webinar.forSurveyor) return true
                if (this.hasPracticeManagerRole && webinar.forPm) return true

                return false
            })
        },
        lastUpcomingWebinar() {
            return this.filteredUpcomingWebinars ? this.filteredUpcomingWebinars.length - 1 : 0
        },
        showMoreLink() {
            return this.previousWebinar.data.length < this.previousWebinar.total
        }
    },
    watch: {
        rolesLoaded(loaded) {
            if (loaded && !this.canViewResources) {
                this.$router.push('/Dashboard')
            }
        },
        search() {
            this.searchPreviousWebinars()
        }
    }
}
</script>
